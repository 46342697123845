<template>

<div>

    <button v-show="!running" @click="generateData()" class="btn btn-primary">Generate</button>
    <button v-show="running" @click="clear()" class="btn btn-warning">clear</button>

    <div v-show="!running" class="row mt-3 mb-5">
        <div class="card">
            <h5 class="card-title">Entrada de dados</h5>
            <div class="card-body">
                <div class="row">
                    <div class="col-3">
                    <div class="form-group">
                        <label for="">Ticker</label>
                        <input type="text" class="form-control" v-model="ticker">
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="">Strike</label>
                        <input type="text" class="form-control" v-model="price">
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="">Step</label>
                        <input type="text" class="form-control" v-model="step">
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="">Interval</label>
                        <input type="text" class="form-control" v-model="interval">
                    </div>
                </div>
                </div>
                
            </div>
        </div>
    </div>

    <div v-show="running" class="card mt-3">
        <div class="card-body">
            <h5 class="card-title">Variação de Valores Ticker {{ ticker }} Step {{ step }} Interval {{ interval }} </h5>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>NQ PX</th>
                        <th>STATUS</th>
                        <th>COUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(gamma, idx) in gammas" :key="idx">
                        <td>{{ gamma.value }}</td>
                        <td>
                            <div class="progress">
                            <div 
                                class="progress-bar" 
                                role="progressbar" 
                                aria-label="Basic example" 
                                :style="{ width: `${gamma.calls_percent}%` }" 
                                :aria-valuenow="gamma.calls_percent" 
                                aria-valuemin="0" aria-valuemax="100">
                                {{ gamma.calls_percent }}%
                            </div>
                            <div 
                                class="progress-bar puts" 
                                role="progressbar" 
                                aria-label="Basic example" 
                                :style="{ width: `${gamma.puts_percent}%` }" 
                                :aria-valuenow="gamma.puts_percent" 
                                aria-valuemin="0" aria-valuemax="100">
                                {{ gamma.puts_percent }}%
                            </div> 
                        </div>
                        </td>
                        <td>
                            Calls: {{ gamma.open_calls }} | Puts: {{ gamma.open_puts }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

</template>

<script>
import { restClient } from '@polygon.io/client-js';
import moment from 'moment'
export default {
    data() {
        return {
            ticker: 'I:NDX',
            price: 13100,
            running: false,
            step: 25,
            interval: 500,
            api_key: 'p4vpQzfG26Mx_v_01VJCfgCDyXO_1rCb',
            function: '',
            gammas: [
                
            ]
        }
    },
    methods: {
        getCalls(strike_origin, gamma){
            const rest = restClient(this.api_key);
            let vm = this;

            let objSearch = {
                "expiration_date.lte" : moment().format('Y-MM-DD'),
                limit: 250,
                contract_type: 'put',
                sort: 'strike_price',
                'strike_price.lte' : strike_origin + 5,
                'strike_price.gte' : strike_origin - 5
            }

            rest.options.snapshotOptionChain(vm.ticker, objSearch).then((data) => {
                gamma.open_puts = 0;
                data.results.forEach( result => {
                    gamma.open_puts += result.open_interest
                } )
                vm.makePercent(gamma);

            }).catch(e => {
                console.error('An error happened:', e);
            });

            objSearch.contract_type = 'call';

            rest.options.snapshotOptionChain(vm.ticker, objSearch).then((data) => {
                gamma.open_calls = 0;
                data.results.forEach( result => {
                    gamma.open_calls += result.open_interest
                } )
                vm.makePercent(gamma);
            }).catch(e => {
                console.error('An error happened:', e);
            });
        },

        makePercent(gamma) {
            gamma.calls_percent = (gamma.open_calls * 100) / (gamma.open_calls + gamma.open_puts);
            gamma.puts_percent = 100 - gamma.calls_percent;
            gamma.calls_percent =  parseFloat(gamma.calls_percent).toFixed(3)
            gamma.puts_percent =  parseFloat(gamma.puts_percent).toFixed(3)
        },

        generateData(){
            let vm = this;
            vm.price = parseInt(vm.price)
            let min = vm.price - parseInt(vm.interval);
            let max = vm.price + parseInt(vm.interval);

            vm.gammas = [];

            for(; max >= min; max -= parseInt(vm.step)){
                let obj = {
                    value: max,
                    name: 'combo4',
                    open_calls: 0,
                    open_puts: 0,
                    calls_percent: 0,
                    puts_percent: 0,
                };

                vm.gammas.push(obj);
            }
       
            vm.running = true;
            vm.function = setInterval( () => {
               
                vm.gammas.forEach( (gamma) => {
                    vm.getCalls(gamma.value, gamma);
                } )
            }, 2000 )
        },

        clear(){
            clearInterval(this.function);
            this.running = false;
            this.gammas = []
        }
    },
    mounted() {
       
        
       
    }

}
</script>