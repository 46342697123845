<template>

<div>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title"> <button @click="getGammas()" class="btn btn-primary"><i class="fa fa-refresh"></i> update gammas</button>  {{ title }} ( {{ count }} )</h5>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>NQ PX</th>
                        <th>LEVEL</th>
                        <th>NAME</th>
                        <th>STATUS</th>
                        <th>COUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(gamma, idx) in gammas" :key="idx">
                        <td>{{ gamma.value }}</td>
                        <td>{{ gamma.name }}</td>
                        <td style="font-size: 10px">
                            <span>Open Interest</span> <br>
                            <span>Call</span> <br>
                            <span>Put</span> <br>
                            <span>PC Ratio</span>
                        </td>
                        <td style="width: 300px">
                            

                            <div class="progress">
                                <div 
                                    class="progress-bar" 
                                    role="progressbar" 
                                    aria-label="Basic example" 
                                    :style="{ width: `${gamma.calls_percent}%` }" 
                                    :aria-valuenow="gamma.calls_percent" 
                                    aria-valuemin="0" aria-valuemax="100">
                                    {{ gamma.calls_percent }}%
                                </div>
                                <div 
                                    class="progress-bar puts" 
                                    role="progressbar" 
                                    aria-label="Basic example" 
                                    :style="{ width: `${gamma.puts_percent}%` }" 
                                    :aria-valuenow="gamma.puts_percent" 
                                    aria-valuemin="0" aria-valuemax="100">
                                    {{ gamma.puts_percent }}%
                                </div> 
                            </div>
                        
                           
                            <div class="progress mt-2">
                                <div 
                                    class="progress-bar" 
                                    role="progressbar" 
                                    aria-label="Basic example" 
                                    :style="{ width: `${gamma.calls_percent_bid}%` }" 
                                    :aria-valuenow="gamma.calls_percent_bid" 
                                    aria-valuemin="0" aria-valuemax="100">
                                    {{ gamma.calls_percent_bid }}%
                                </div>
                                <div 
                                    class="progress-bar puts" 
                                    role="progressbar" 
                                    aria-label="Basic example" 
                                    :style="{ width: `${gamma.calls_percent_ask}%` }" 
                                    :aria-valuenow="gamma.calls_percent_ask" 
                                    aria-valuemin="0" aria-valuemax="100">
                                    {{ gamma.calls_percent_ask }}%
                                </div> 
                            </div>

                            <div class="progress mt-2">
                                <div 
                                    class="progress-bar" 
                                    role="progressbar" 
                                    aria-label="Basic example" 
                                    :style="{ width: `${gamma.puts_percent_bid}%` }" 
                                    :aria-valuenow="gamma.puts_percent_bid" 
                                    aria-valuemin="0" aria-valuemax="100">
                                    {{ gamma.puts_percent_bid }}%
                                </div>
                                <div 
                                    class="progress-bar puts" 
                                    role="progressbar" 
                                    aria-label="Basic example" 
                                    :style="{ width: `${gamma.puts_percent_ask}%` }" 
                                    :aria-valuenow="gamma.puts_percent_ask" 
                                    aria-valuemin="0" aria-valuemax="100">
                                    {{ gamma.puts_percent_ask }}%
                                </div> 
                            </div>

                            <div class="progress mt-2">
                                <div 
                                    class="progress-bar" 
                                    role="progressbar" 
                                    aria-label="Basic example" 
                                    :style="{ width: `${gamma.percent_blue}%` }" 
                                    :aria-valuenow="gamma.percent_blue" 
                                    aria-valuemin="0" aria-valuemax="100">
                                    {{ gamma.percent_blue }}%
                                </div>
                                <div 
                                    class="progress-bar puts" 
                                    role="progressbar" 
                                    aria-label="Basic example" 
                                    :style="{ width: `${gamma.percent_red}%` }" 
                                    :aria-valuenow="gamma.percent_red" 
                                    aria-valuemin="0" aria-valuemax="100">
                                    {{ gamma.percent_red }}%
                                </div> 
                            </div>
                        </td>
                        <td style="font-size: 10px">
                            <span>Calls: {{ gamma.open_calls }} | Puts: {{ gamma.open_puts }}</span> <br>
                            <span>Bid: {{ gamma.call_bid_size }} | Ask: {{ gamma.call_ask_size }}</span> <br>
                            <span>Bid: {{ gamma.put_bid_size }} | Ask: {{ gamma.put_ask_size }}</span> <br>
                            <span>B: {{ gamma.call_bid_size + gamma.put_ask_size  }} | R: {{ gamma.call_ask_size + gamma.put_bid_size }}</span> <br>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

</template>

<script>
import { restClient } from '@polygon.io/client-js';
import axios from 'axios'
export default {

    data() {
        return {
            count: 0,
            api_key: 'p4vpQzfG26Mx_v_01VJCfgCDyXO_1rCb',
            title: '',
            gammas: []
        }
    },
    methods: {
        getGammas(){
            axios.get('https://credizila.com.br/gammas')
            .then( res => {
                this.gammas = res.data.gammas;
                this.title = res.data.title;
                this.gammas.forEach((gamma) => {
                    gamma.value = parseInt(gamma.value);
                })
                this.startRun();
            } )
        },

        startRun(){
            let vm = this;
            setInterval( () => {
                vm.gammas.forEach( (gamma) => {
                    vm.getCalls(gamma.value, gamma);
                } )
            }, 1000 )
        },

        getCalls(strike_origin, gamma){
            const rest = restClient(this.api_key);
            let ticker = 'I:NDX';
            let vm = this;


            let objSearch = {
                "expiration_date.lte" : '2023-07-14',
                limit: 250,
                contract_type: 'put',
                sort: 'strike_price',
                'strike_price.lte' : strike_origin + 5,
                'strike_price.gte' : strike_origin - 5
            }


            rest.options.snapshotOptionChain(ticker, objSearch).then((data) => {
                this.count++;
                gamma.open_puts = 0;
                gamma.put_bid_size = 0
                gamma.put_ask_size = 0
                data.results.forEach( result => {
                    gamma.open_puts += result.open_interest
                    gamma.put_bid_size += result.last_quote.bid_size
                    gamma.put_ask_size += result.last_quote.ask_size
                } )
                vm.makePercent(gamma);

            }).catch(e => {
                console.error('An error happened:', e);
            });

            objSearch.contract_type = 'call';

            rest.options.snapshotOptionChain(ticker, objSearch).then((data) => {
                this.count++;
                gamma.open_calls = 0;
                gamma.call_bid_size = 0
                gamma.call_ask_size = 0
                data.results.forEach( result => {
                    gamma.open_calls += result.open_interest
                    gamma.call_bid_size += result.last_quote.bid_size
                    gamma.call_ask_size += result.last_quote.ask_size
                } )
                vm.makePercent(gamma);
            }).catch(e => {
                console.error('An error happened:', e);
            });
        },

        makePercent(gamma) {
            gamma.calls_percent = (gamma.open_calls * 100) / (gamma.open_calls + gamma.open_puts);
            gamma.puts_percent = 100 - gamma.calls_percent;
            gamma.calls_percent =  parseFloat(gamma.calls_percent).toFixed(2)
            gamma.puts_percent =  parseFloat(gamma.puts_percent).toFixed(2)
            gamma.calls_percent_ask =  (gamma.call_ask_size * 100) /(gamma.call_ask_size + gamma.call_bid_size)
            gamma.calls_percent_ask = parseFloat(gamma.calls_percent_ask).toFixed(2)
            gamma.calls_percent_bid = 100 - gamma.calls_percent_ask
            gamma.puts_percent_ask =  (gamma.put_ask_size * 100) /(gamma.put_ask_size + gamma.put_bid_size)
            gamma.puts_percent_ask = parseFloat(gamma.puts_percent_ask).toFixed(3)
            gamma.puts_percent_bid = 100 - gamma.puts_percent_ask
            gamma.qtd_cruze_1 = (gamma.call_bid_size + gamma.put_ask_size)
            gamma.qtd_cruze_2 = (gamma.call_ask_size + gamma.put_bid_size)
            gamma.percent_blue = (gamma.qtd_cruze_1 * 100) /(gamma.qtd_cruze_1 + gamma.qtd_cruze_2)
            gamma.percent_blue = parseFloat(gamma.percent_blue).toFixed(2)
            gamma.percent_red = 100 - gamma.percent_blue;
        }
    },
    mounted(){
        this.startRun();
    }

}
</script>