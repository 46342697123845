<template>

<div>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">{{ ticker }}</h5>
            <div class="card-content">
               <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Open</th>
                        <th>Close</th>
                        <th>Variação</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :class="`indice-data-${date.date}`" v-for="(date, idx) in dates" :key="`date-${idx}`">
                        <td data-indice="date">{{ date.date }}</td>
                        <td data-indice="open">{{ date.open }}</td>
                        <td data-indice="close">{{ date.close }}</td>
                        <td data-indice="variation-percent">{{ date.variationPercent }}</td>
                    </tr>
                    <tr :class="`indice-data-${date.date}`" v-for="(date, idx) in date_now" :key="`date-${idx}`">
                        <td data-indice="date">{{ date.date }}</td>
                        <td data-indice="open">{{ date.open }}</td>
                        <td data-indice="close">{{ date.close }}</td>
                        <td data-indice="variation-percent">{{ date.variationPercent }}</td>
                    </tr>

                </tbody>
               </table>
            </div>
        </div>
    </div>

</div>

</template>

<script>
import { restClient } from '@polygon.io/client-js';
import axios from 'axios';
import moment from 'moment'

export default{
    props: ['ticker'],
    data() {
        return {
            api_key: 'p4vpQzfG26Mx_v_01VJCfgCDyXO_1rCb',
            dates: [],
            date_now: [],
            actualValue: 0
        }
    },
    methods: {

        getHistory(){
            let vm = this;
            const rest = restClient(this.api_key);

            let startDate = moment().subtract(10, 'days').format('Y-MM-DD');
            let endDate = moment().subtract(1, 'days').format('Y-MM-DD');

            rest.indices.aggregates(this.ticker, 1, "day", startDate, endDate).then((data) => {
                data.results.forEach( (result) => {
                    let obj = {
                        date: moment(result.t).format('Y-MM-DD'),
                        close: result.c.toFixed(3),
                        open: result.o.toFixed(3) 
                    }

                    let variationPercent = ((obj.close * 100) / obj.open) - 100
                    
                    obj.variationPercent = variationPercent.toFixed(4);
                    vm.dates.push(obj)

            
                } )
                setInterval( () => {
                    this.getNow();
                }, 2000 )
            }).catch(e => {
                console.error('An error happened:', e);
            });
        },


        getNow(){

            let vm = this;
            // https://polygon.io/docs/indices/get_v3_snapshot_indices
            axios.get('https://api.polygon.io/v3/snapshot/indices?ticker='+ this.ticker +'&apiKey=' + this.api_key)
            .then( res => {
                vm.date_now = [];
                let obj = {
                        date: moment().format('Y-MM-DD'),
                        close: res.data.results[0].session.close.toFixed(3),
                        open: res.data.results[0].session.open.toFixed(3) 
                    }

                    let variationPercent = ((obj.close * 100) / obj.open) - 100
                    
                    obj.variationPercent = variationPercent.toFixed(4);
                    vm.date_now.push(obj)
            } )
        }

    },
    mounted() {
        this.getHistory();
        // this.getNow();
    }
}

</script>