<template>


<div class="row">
  <div class="col-2">
      <button class="btn btn-primary" @click="showCharts()">Toggle Charts</button>
    </div>
</div>

<indice-group></indice-group>


<div class="container-fluid">

  <div class="row">

      <div class="nftmax-history mg-top-40">
              <div class="nftmax-history__main">
                  <div class="nftmax-history__content">
                      <div class="nftmax-history__icon nftmax-history__icon-one"><img src="/assets/img/history-icon-1.png" alt="#"></div>
                      <div class="nftmax-history__text">
                          <!-- <h4 class="nftmax-history__number"><span class="number">71</span></h4> -->
                          <p class="nftmax-history__text">Mercado Geral</p>
                      </div>
                  </div>
                  <div class="nftmax-history__canvas">
                      
                    <h1 class="center">Mercado Geral</h1>
                    <div class="progress">
                          <div 
                              class="progress-bar" 
                              role="progressbar" 
                              aria-label="Basic example" 
                              :style="{ width: `${percentCalls}%` }" 
                              :aria-valuenow="percentCalls" 
                              aria-valuemin="0" aria-valuemax="100">
                              {{ percentCalls }}% ( {{ totalCalls }})
                          </div>
                          <div 
                              class="progress-bar puts" 
                              role="progressbar" 
                              aria-label="Basic example" 
                              :style="{ width: `${percentPuts}%` }" 
                              :aria-valuenow="percentPuts" 
                              aria-valuemin="0" 
                              aria-valuemax="100">
                              {{ percentPuts }}% ({{ totalPuts }})
                          </div>  
                      </div>
                      
                  </div>
              </div>
      </div>

      <div class="nftmax-history mg-top-40">
              <div class="nftmax-history__main">
                  <div class="nftmax-history__content">
                      <div class="nftmax-history__icon nftmax-history__icon-one"><img src="/assets/img/history-icon-1.png" alt="#"></div>
                      <div class="nftmax-history__text">
                          <!-- <h4 class="nftmax-history__number"><span class="number">71</span></h4> -->
                          <p class="nftmax-history__text">Mercado Geral</p>
                      </div>
                  </div>
                  <div class="nftmax-history__canvas">
                      
                    <h1 class="center">Weighted Mercado Geral</h1>
                    <div class="progress">
                        <div 
                            class="progress-bar" 
                            role="progressbar" 
                            aria-label="Basic example" 
                            :style="{ width: `${callsWithHeightPercent}%` }" 
                            :aria-valuenow="callsWithHeightPercent" 
                            aria-valuemin="0" aria-valuemax="100">
                            {{ callsWithHeightPercent }}%
                        </div>
                        <div 
                            class="progress-bar puts" 
                            role="progressbar" 
                            aria-label="Basic example" 
                            :style="{ width: `${100 -callsWithHeightPercent}%` }" 
                            :aria-valuenow="100 -callsWithHeightPercent" 
                            aria-valuemin="0" 
                            aria-valuemax="100">
                            {{ 100 -callsWithHeightPercent }}% 
                        </div>  
                    </div>
                      
                  </div>
              </div>
      </div>


  </div>

  <div class="row">
    <!-- <div v-for="(ticker, idx) in tickers" :key="`ticker-${ticker}-${idx}`" class="col-12 col-md-4">
      <exchange-graph @sums="update" :ticker="ticker"></exchange-graph>
    </div> -->
    <!-- <div class="col-4">
      <exchange-graph ticker="GOOG"></exchange-graph>
    </div>
    <div class="col-4">
      <exchange-graph ticker="MSFT"></exchange-graph>
    </div>
    <div class="col-4">
      <exchange-graph ticker="TSLA"></exchange-graph>
    </div>
    <div class="col-4">
      <exchange-graph ticker="AMZN"></exchange-graph>
    </div>
    <div class="col-4">
      <exchange-graph ticker="NVDA"></exchange-graph>
    </div> -->
  </div>




</div>

  <!-- <exchange-graph ticker="SPY"></exchange-graph> -->
  <!-- <exchange-graph ticker="GOOG"></exchange-graph> -->
</template>

<script>
import IndiceGroup from './components/IndiceGroup.vue'

export default {
  name: 'App',
    components: {
      IndiceGroup
    },
    data() {
      return {
        tickers: [
          'AAPL',
          'GOOG',
          'MSFT',
          'TSLA',
          'AMZN',
          'NVDA',
        ],
        sums: {},
        totalCalls:0,
        totalPuts:0,
        percentCalls :0,
        percentPuts :0,
        result : {
          AAPL : {
            calls: 0,
            puts: 0,
          },
          MSFT : {
            calls: 0,
            puts: 0,
          },
          GOOG : {
            calls: 0,
            puts: 0,
          },
          TSLA : {
            calls: 0,
            puts: 0,
          },
          AMZN : {
            calls: 0,
            puts: 0,
          },
          NVDA : {
            calls: 0,
            puts: 0,
          },
        },
        actual_calls: 0,
        actual_puts: 0,
        callsWithHeight: 0,
        callsWithHeightPercent: 0,
        iterators : 0,
        displayChart: true,
      }
  },
  methods: {

    showCharts(){
      this.displayChart = !this.displayChart;
      document.querySelectorAll('.chart-show').forEach( (chart) => {
        chart.style.display = this.displayChart ? 'block' : 'none';
      } )
    },  

    hideCars(){

    },

    getCallsWithHeight() {


      // this.tickers.forEach((ticker) => {
        // this.result[ticker].calls = document.querySelector('[data-id="' + ticker + '-CALLS"]').getAttribute('aria-valuenow')
        // this.result[ticker].calls = parseFloat(this.result[ticker].calls);
        // this.result[ticker].puts = 100 - this.result[ticker].calls;
      // })

    


      this.callsWithHeight = 
      this.result.AAPL.calls * 13.06 +
      this.result.GOOG.calls * 3.68 +
      this.result.MSFT.calls * 10.6 +
      this.result.AMZN.calls * 6.38 +
      this.result.NVDA.calls * 3.24 +
      this.result.TSLA.calls * 4.48

      this.callsWithHeightPercent = this.callsWithHeight / 41.44;


    },
    update(ticker, values){


      this.sums[ticker] = values;
      this.totalCalls = 0;
      this.totalPuts = 0;
      let qtd_calls = 0;
      let qtd_calls_now = 0;
      let qtd_puts = 0
      let vm = this;

          Object.keys(this.sums).forEach( (sum) => {
            vm.actual_calls = 0;
            vm.actual_puts = 0;
            vm.iterators = 0;
            qtd_calls_now = 0;
            Object.keys(this.sums[sum]).forEach( (inside) => {
              vm.iterators++;
              let operation = this.sums[sum][inside];
              vm.totalCalls = vm.totalCalls + operation.calls;
              vm.totalPuts = vm.totalPuts + operation.puts;

              if(typeof parseFloat(operation.percent_calls) == 'number'){
                
                qtd_calls = qtd_calls + parseFloat(operation.percent_calls);
                qtd_calls_now = qtd_calls;
              }
              if(typeof parseFloat(operation.percent_puts) == 'number'){
                qtd_puts = qtd_puts + parseFloat(operation.percent_puts);
              }
              

        } )
        vm.actual_calls = qtd_calls_now;
        vm.actual_puts = qtd_puts;

        // console.log(sum);
        // console.log(vm.actual_calls);
        vm.actual_calls = vm.actual_calls / vm.iterators;
        // console.log(sum);

        // console.log(vm.actual_calls);

        vm.actual_puts = vm.actual_puts / vm.iterators;

        

        // vm.result[sum].calls =   parseFloat(vm.actual_calls * 100) / (vm.actual_calls + vm.actual_puts);
        // vm.result[sum].puts =   parseFloat(vm.actual_puts * 100) / (vm.actual_calls + vm.actual_puts);

      } )

      let totalValue = qtd_calls + qtd_puts;

      
      this.percentCalls =  parseFloat(qtd_calls * 100) / totalValue;
      this.percentPuts = 100 - parseFloat(this.percentCalls);
      this.percentCalls = (Math.round(this.percentCalls * 100) / 100).toFixed(2);
      this.percentPuts = (Math.round(this.percentPuts * 100) / 100).toFixed(2);
                
    }
  },
  mounted() {
    setInterval(() => {
      this.getCallsWithHeight();

    }, 1000 )

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.puts{
    background-color: red;
}

.chart-show{
    /* display: none; */
}


table{
  font-size: 13px;
}

</style>


