<template>

<div class="row mt-3">
    <bar-gamma-step></bar-gamma-step>
</div>
<div class="row-mt-3">
    <bar-gamma></bar-gamma>
</div>
    <div class="row mt-5">
        <div class="col-12" id="chart"></div>
    </div>

<div class="row mt-3">
    <indice-ticker class="col-6" :ticker="'I:NDX'"></indice-ticker>
    <indice-ticker class="col-6" :ticker="'I:SPX'"></indice-ticker>
</div>

<!-- <div class="row mt-3">
    <table><tbody><tr><th bgcolor="lightgray" colspan="3">Date: 2023-05-15<br>Key SpotGamma Levels:</th></tr><tr><td>NDX PX</td><td>NQ PX</td><td align="right">Level ID</td></tr><tr><td>13754</td><td>13767</td><td align="right">Combo4</td></tr><tr><td>13540</td><td>13553</td><td align="right">Combo2</td></tr><tr><td>13340</td><td>13353</td><td align="right">Combo3</td></tr><tr><td>13300</td><td>13313</td><td align="right">Large Gamma1</td></tr><tr><td>13200</td><td>13213</td><td align="right">Large Gamma2</td></tr><tr><td>13175</td><td>13188</td><td align="right">Put Wall</td></tr><tr><td>13000</td><td>13013</td><td align="right">Large Gamma3</td></tr><tr><td>12980</td><td>12993</td><td align="right">Combo1</td></tr><tr><td>12975</td><td>12988</td><td align="right">Large Gamma4</td></tr><tr><td>12975</td><td>12988</td><td align="right">Call Wall</td></tr><tr><td>12927</td><td>12940</td><td align="right">Combo5</td></tr><tr><td>12890</td><td>12903</td><td align="right">Vol Trig</td></tr></tbody></table>
</div> -->

</template>


<script>

import IndiceTicker from './IndiceTicker.vue';
import Plotly from 'plotly.js-dist'
import moment from 'moment'
import BarGamma from './BarGamma.vue';
import BarGammaStep from './BarGammaStep.vue';


export default {
    components: { IndiceTicker,BarGamma, BarGammaStep },
    data() {
        return {
            response: [],
            rendered: false,
        }
    },
    methods: {
        getValues(){
            this.response = [];
            for(let i = 10; i >= 0; i--){
                let startDate = moment().subtract(i, 'days').format('Y-MM-DD');

                let indicesOpen = document.querySelectorAll(`.indice-data-${startDate}>[data-indice="variation-percent"]`);
                let result_open = 0;

                indicesOpen.forEach(indice => {
                    result_open += parseFloat(indice.innerHTML);
                })

                if(!isNaN(result_open / indicesOpen.length)){

                    let obj = {
                        date: startDate,
                        variation: result_open / indicesOpen.length
                    }

                    this.response.push(obj);

                }
               
            }
            this.makeGraph()
        },
        makeGraph() {
            if(!this.rendered){
                let xLine = [];
                let yLine = [];

                this.response.forEach( resp => {
                    xLine.push(resp.date)
                    yLine.push(resp.variation)
                } )



                var trace1 = {
                    x: xLine,
                    y: yLine,
                    type: 'scatter'
                };

                var data = [trace1];

                Plotly.newPlot('chart', data);
            }
            
        }
    },  
    mounted() {

        setInterval( () => {
            this.getValues();
        }, 2000 )
    }
}

</script>